import React, { Suspense } from "react";

const quickCard = React.lazy(() => import("./BankingPlus/CardQuickReply"));
const CarouselCards = React.lazy(() => import("./BankingPlus/CarouselCards"));
const LocationCarouselCards = React.lazy(
  () => import("./BankingPlus/LocationCarouselCards")
);
const cardRadioBullet = React.lazy(
  () => import("./BankingPlus/CardRadioBullet")
);
const confirmationCard = React.lazy(
  () => import("./BankingPlus/CardConfirmation")
);
// const Buttons = React.lazy(() => import("./BankingPlus/Buttons"));
// const buttons = React.lazy(() => import("./BankingPlus/Buttons"));
const CardRadio = React.lazy(() => import("./BankingPlus/CardRadio"));
const cardList = React.lazy(() => import("./BankingPlus/CardList"));

const multiSelect = React.lazy(() => import("./InsurancePlus/MultiSelect"));
const autoPolicy = React.lazy(() => import("./InsurancePlus/AutoPolicy"));

const healthPlan = React.lazy(() => import("./HealthPlus/MedicalPlanUsage"));
const delayedReply = React.lazy(() => import("./FaqAssistant/DelayedReply"));

const openai = React.lazy(() => import("./OpenAI/ResponseCard"));
const buttons = React.lazy(() => import("./Venmo/PromptButtons"));
const greetings = React.lazy(() => import("./shared/GreetingsCard"));

const driverVehicle = React.lazy(() => import("./FNOL/DriverVehicle"));
const otherPartyInformation = React.lazy(
  () => import("./FNOL/OtherPartyInformation")
);
const otherPartyDriverLicenseInsurance = React.lazy(
  () => import("./FNOL/OtherPartyDriverLicense_Insurance")
);
const otherPartyDriverContact = React.lazy(
  () => import("./FNOL/OtherPartyDriverContact")
);
const dateTime = React.lazy(() => import("./FNOL/DateTime"));
const damageParts = React.lazy(() => import("./FNOL/DamageParts"));
const reviewAndConfirm = React.lazy(() => import("./FNOL/ReviewAndConfirm"));
const searchRepairShop = React.lazy(() => import("./FNOL/SearchRepairShop"));
const searchResults = React.lazy(() => import("./FNOL/SearchResults"));
const cancel = React.lazy(() => import("./FNOL/Cancel"));
const confirmCollectedData = React.lazy(
  () => import("./FNOL/ConfirmCollectedData")
);
const claimProcessProgress = React.lazy(
  () => import("./FNOL/ClaimProcessProgress")
);
const locationOfIncident = React.lazy(
  () => import("./FNOL/LocationOfIncident")
);
const injuryDamageDetails = React.lazy(
  () => import("./FNOL/InjuryDamageDetails")
);
const incidentDetails = React.lazy(() => import("./FNOL/IncidentDetails"));
const claimSubmissionStatus = React.lazy(
  () => import("./FNOL/ClaimSubmissionStatus")
);
const vehicleInformation = React.lazy(
  () => import("./FNOL/VehicleInformation")
);
const location = React.lazy(() => import("./FNOL/Location"));
const IncidentTypeConfirmation = React.lazy(
  () => import("./InsurancePlus/IncidentTypeConfirmation")
);
// const fnolForm = React.lazy(() => import("./FNOL/FNOLForm"));
const roadsideAssistanceContactInfo = React.lazy(
  () => import("./FNOL/RoadsideAssistanceContactInfo")
);
const assistanceType = React.lazy(() => import("./FNOL/AssistanceType"));
const towDestination = React.lazy(() => import("./FNOL/TowDestination"));
const reviewAndConfirmRoadside = React.lazy(
  () => import("./FNOL/ReviewAndConfirmRoadside")
);
const assistanceRequested = React.lazy(
  () => import("./FNOL/AssistanceRequested")
);
const form = React.lazy(() => import("./form/form"));
const visual_picker = React.lazy(() => import("./form/VisualPicker"));

const registeredComponents = {
  quickCard,
  CarouselCards,
  LocationCarouselCards,
  cardRadioBullet,
  confirmationCard,
  // Buttons,
  buttons,
  CardRadio,
  cardList,

  multiSelect,
  autoPolicy,

  healthPlan,
  delayedReply,

  openai,
  // button,
  greetings,

  driverVehicle,
  otherPartyInformation,
  otherPartyDriverLicenseInsurance,
  otherPartyDriverContact,
  dateTime,
  damageParts,
  reviewAndConfirm,
  searchRepairShop,
  searchResults,
  cancel,
  confirmCollectedData,
  claimProcessProgress,
  locationOfIncident,
  injuryDamageDetails,
  incidentDetails,
  claimSubmissionStatus,
  vehicleInformation,
  location,
  IncidentTypeConfirmation,
  // fnolForm,
  roadsideAssistanceContactInfo,
  assistanceType,
  towDestination,
  reviewAndConfirmRoadside,
  assistanceRequested,
  form,
  visual_picker
};

export default function CustomComponent(props) {
  const CustomComponent = registeredComponents[props.component];
  const content = props.data;
  const scrollToBottom = props.scrollToBottom;

  return (
    <Suspense>
      <CustomComponent data={content} scrollToBottom={scrollToBottom} />
    </Suspense>
  );
}
