import "../../css/Shared/BotTyping.scss";

export default function BotTyping() {
  return (
    <div className="typing-wrapper bot-text-wrapper">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
