import { useEffect, useRef } from "react";
import {
  addToConversation,
  conversationState,
  disableInput,
  isBotTyping,
  websocket,
  messageBroker
} from "./store/chatStore";
import BotTyping from "./components/shared/BotTyping";
import { effect } from "@preact/signals-react";
import { SurfaceMessage, SurfaceMessageType, Value } from "./types";

import CustomComponent from "./components/CustomComponent";

export default function ChatBotMessageBox() {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, 100);
  };

  //Handling auto scroll
  useEffect(() => {
    scrollToBottom();
  });

  effect(() => {
    if (websocket.value) {
      websocket.value.onmessage = async (event) => {
        try {
          const result: SurfaceMessage = JSON.parse(event.data);

          if (result.closeConversation) {
            disableInput.value = true;
          }

          if (result.pendingStatus && result.pendingStatus === true) {
            isBotTyping.value = true;
          } else {
            isBotTyping.value = false;
          }

          if (result.type === SurfaceMessageType.TEXT) {
            //check if data is list or string
            //check if result.text exists
            if (result?.text) {
              addToConversation({
                bot: true,
                element: result.text,
              });
            } else if (result.data && Array.isArray(result.data)) {
              result.data.forEach((text) => {
                addToConversation({
                  bot: true,
                  element: text,
                });
              });
            }
          } else if (result.type == SurfaceMessageType.CUSTOM) {
            if (result.text && result.text.length) {
              addToConversation({
                bot: true,
                element: result.text,
              });
            }
            const component = result.component;
            const content: Record<string, Value> | undefined = result.data;

            if (!component) {
              throw new Error("Component not specified");
            }
            if (!CustomComponent) {
              console.error(
                `No custom component of type ${component} to handle incoming data - OnMessage`
              );
              return;
            }
            const obj = {
              bot: true,
              element: (
                <CustomComponent
                  component={component}
                  data={content}
                  scrollToBottom={scrollToBottom}
                />
              ),
              // TODO refactor this out to proper config
              isOpenAI: component === "openai" || component === "greetings",
            };
            addToConversation(obj);
          } else {
            messageBroker.value.sendMessage(result);
          }


          // TODO handle button type
        } catch (error) {
          console.log(error);
          addToConversation({
            bot: true,
            element:
              "We’re having trouble connecting to the chat. Please try again later.",
          });

          isBotTyping.value = false;
        }
      };
    }
  });

  return (
    <div ref={containerRef} className="ts-message-box">
      {conversationState.value.map((convo, i) =>
        convo.bot ? (
          <div className="bot" key={i}>
            {/<\/?[a-z][\s\S]*>/i.test(convo.element) ? (
              <div
                className="bot-text-wrapper"
                dangerouslySetInnerHTML={{ __html: convo.element }}
              ></div>
            ) : (
              <div className="bot-text-wrapper">{convo.element}</div>
            )}
          </div>
        ) : (
          <div className="user" key={i}>
            <div className="user-text-wrapper">{convo.element}</div>
          </div>
        )
      )}
      {isBotTyping.value && <BotTyping />}
    </div>
  );
}
